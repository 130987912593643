import request from '../utils/request';

/**
 * 获取卡种列表
 * @param query
 * @returns {AxiosPromise}
 */
export const cardList = (query) => {
    return request({
        url: '/sys/card/cardList',
        method: 'post',
        data: query
    });
}

export const cardList02 = (region) => {
    return request({
        url: '/sys/card/cardList01?regionId=' + region,
        method: 'get',
    });
}
/**
 * 修改状态
 * @param query
 * @returns {AxiosPromise}
 */
export const editSate = (id, state) => {
    return request({
        url: '/sys/card/editState',
        method: 'get',
        params: {
            id,
            state
        }
    });
}
/**
 * 删除
 * @param id
 * @returns {AxiosPromise}
 */
export const del = (id) => {
    return request({
        url: '/sys/card/del',
        method: 'get',
        params: {
            id
        }
    });
}
/**
 * 新增卡种时 卡种类型下拉数据
 * @param id
 * @returns {AxiosPromise}
 */
export const elList = () => {
    return request({
        url: '/sys/card/elList',
        method: 'get',
    });
}
/**
 * 新增
 * @param card
 * @returns {AxiosPromise}
 */
export const add = (card) => {
    return request({
        url: '/sys/card/cardAdd',
        method: 'post',
        data: card
    });
}
/**
 * 更新
 * @param card
 * @returns {AxiosPromise}
 */
export const edit = (card) => {
    return request({
        url: '/sys/card/edit',
        method: 'post',
        data: card
    });
};

/**
 * 修改规格状态
 * @param id
 * @param state
 * @returns {AxiosPromise}
 */
export const updateSpeState = (speId, state) => {
    return request({
        url: '/sys/chatGoods/updateSpeState',
        method: 'get',
        params: {
            speId,
            state
        }
    });
};
/**
 * 修改卡片列表状态
 * @param id
 * @param state
 * @returns {AxiosPromise}
 */
export const updateState = (id) => {
    return request({
        url: '/sys/card2/updateCardStateById',
        method: 'get',
        params: {id}
    });
};
/**
 * 修改赠送状态
 * @param speId
 * @param state
 * @returns {AxiosPromise}
 */
export const updateChatSendGoodsState = (chatSendGoodId, state) => {
    return request({
        url: '/sys/chatGoods/updateChatSendGoodsState',
        method: 'get',
        params: {
            chatSendGoodId,
            state
        }
    });
};
/**
 *新增选择类型
 * @param cardId
 * @param type
 * @returns {AxiosPromise}
 */
export const selectCardOrEqu = (cardId, type) => {
    return request({
        url: '/sys/chatGoods/selectCardOrEqu',
        method: 'get',
        params: {
            cardId,
            type
        }
    });
};
/**
 * 新增赠送
 * @param chatSendOrder
 * @returns {AxiosPromise}
 */
export const addSendGoods = (chatSendOrder) => {
    return request({
        url: '/sys/chatGoods/insertSendGoods',
        method: 'POST',
        data: chatSendOrder
    });
};
/**
 * 新增规格
 * @param chatSendOrder
 * @returns {AxiosPromise}
 */
export const addCardSpec = (specifications) => {
    return request({
        url: '/sys/chatGoods/insertCardSpecifications',
        method: 'POST',
        data: specifications
    });
};

/***
 * 导出
 * @param query
 * @returns {AxiosPromise}
 */
export function exportExcel(query) {
    return request({
        url: '/sys/card2/excel',
        method: 'post',
        data: query,
        responseType: 'arraybuffer'
    })
}

/***
 * web编辑订单查询卡片
 * @param query
 * @returns {AxiosPromise}
 */
export function selectCardUpdateOrder(campusId) {
    return request({
        url: '/sys/card2/selectCardUpdateOrder',
        method: 'get',
        params: {campusId},
    })
}

/**
 * *****************************   新卡片接口    *************************************************
 */


/**
 * 查询卡片list
 * @param chatSendOrder
 * @returns {AxiosPromise}
 */
export const getCardList = (card) => {
    return request({
        url: '/sys/card2/selectCardList',
        method: 'POST',
        // headers:{pageIndex,pageSize},
        data: card
    });
};
//添加卡片
export const addCard = (query) => {
    return request({
        url: '/sys/card2/addCard',
        method: 'POST',
        data: query
    });
};


/**
 * 查询卡片下面的规格  已经规格的属性值
 * @param chatSendOrder
 * @returns {AxiosPromise}
 */
export const selectSpecByCardId = (cardId) => {
    return request({
        url: '/sys/card2/selectSpecByCardId',
        method: 'get',
        params: {cardId},
    });
};

/**
 * 查询课程
 * @param specAttribute
 * @returns {AxiosPromise}
 */
export const selectCourse = (courseQuery) => {
    return request({
        url: '/sys/card2/selectCourse',
        method: 'POST',
        data: courseQuery
    });
};

/**
 * 添加卡片规格和添加规格属性值
 * @param specAttribute
 * @returns {AxiosPromise}
 */
export const addSpecOrAttribute = (specAttribute) => {
    return request({
        url: '/sys/card2/addSpecOrAttribute',
        method: 'POST',
        data: specAttribute
    });
};

//添加卡片课程规则
export const addCardAttributeRule = (form) => {
    return request({
        url: '/sys/card2/addCardAttributeRule',
        method: 'POST',
        data: form
    });
};

//修改卡片
export const updateCardById = (card) => {
    return request({
        url: '/sys/card2/updateCardById',
        method: 'POST',
        data: card
    });
};
//查询卡片
export const searchCard = (name) => {
    return request({
        url: '/sys/card2/selectRuleByRoleIdAndParam',
        method: 'POST',
        data: name
    });
};
//编辑课程卡片
export const updateRuleById = (name) => {
    return request({
        url: '/sys/card2/updateRuleById',
        method: 'POST',
        data: name
    });
};
//api/sys/courseRole/addRoleAndRoleSubset
//添加卡片角色
export const addRoleAndRoleSubset = (query) => {
    return request({
        url: '/sys/courseRole/addRoleAndRoleSubset',
        method: 'POST',
        data: query
    });
};
//修改卡片角色
export const editRoleAndSub=(query)=> {
    return request({
        url: '/sys/courseRole/addRoleAndRoleSubset',
        method: 'POST',
        data: query
    });
};
//根据城市id 获取多级区域
export const listAreaTreeByCity = (id,type) => {
    return request({
        url: '/sys/area/listAreaTreeByCity',
        method: 'get',
        params: {id,type}
    });
};

export const listAreaTreeByCityPost = (flagCityQuery) => {
    return request({
        url: '/sys/area/listAreaTreeByCityPost',
        method: 'POST',
        data: flagCityQuery,
        headers:{
            'Content-Type':'application/json;charset=UTF-8',
        }
    });
};

//修改课程状态
export const updateStateByRuleId = (id,state) => {
    return request({
        url: '/sys/card2/updateStateByRuleId',
        method: 'get',
        params: {id,state}
    });
};
//课程赠送状态修改
export const updateSendStateByGiftId = (id,state) => {
    return request({
        url: '/sys/card2/updateSendStateByGiftId',
        method: 'get',
        params: {id,state}
    });
};

//获取角色List
// /sys/role/getRoleListByJsonItem
export const getRoleListByJsonItem = (item) => {
    return request({
        url: '/sys/role/getRoleListByAreaId',
        method: 'POST',
        data: item,
        headers:{
            'Content-Type':'application/json;charset=UTF-8',
        }
    });
};

export const selectUpdateCardLog = (id) => {
    return request({
        url: '/sys/card2/selectUpdateCardLog',
        method: 'POST',
        data: id,
        headers:{
            'Content-Type':'application/json;charset=UTF-8',
        }
    });
};

/**
 * 组合卡提报
 * @param id
 * @returns {AxiosPromise}
 */
export const addCardGift = (list) => {
    return request({
        url: '/sys/card2/addCardGift',
        method: 'POST',
        data: list
    });
};

